import React, { useContext, useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { API } from 'aws-amplify'
import { Link } from 'gatsby'
import { Buffer } from 'buffer'
import { Decoder } from '@nuintun/qrcode'
import { GlobalStateContext } from '../../context/GlobalContextProvider'
import {
  Grid,
  Card,
  CardHeader,
  IconButton,
  Box,
  Typography,
  Button,
  CardContent,
  CircularProgress,
} from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import QrCodeIcon from '@mui/icons-material/QrCode'
import KeyIcon from '@mui/icons-material/Key'
import SmartphoneIcon from '@mui/icons-material/Smartphone'
import InfoIcon from '@mui/icons-material/Info'
import Hider from '../utils/Hider'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { navigate } from 'gatsby'

export default function AddDevice({
  location,
  open,
  setOpen,
  setLimitWarning,
  limitWarning,
}) {
  const [keyList, setKeyList] = useState([])
  const [href, setHref] = useState('')
  const [keyWarning, setKeyWarning] = useState(false)
  const { gStripe, gAuth, gLoginEmployee, gCompanyDevice } =
    useContext(GlobalStateContext)
  const [getPersonalDevice, setGetPersonalDevice] = useState(false)
  const [isPending, setIsPending] = useState()

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  })

  const readQrcode = (imgUrl) => {
    new Decoder()
      .scan(URL.createObjectURL(imgUrl))
      .then((result) => {
        const urlParams = new URLSearchParams(result.data)
        const sk = urlParams.get('sk').replace(/ /g, '+')
        const data = Buffer.from(sk, 'base64')
        const lengths = [8, 4, 4, 4, 12]
        let parts = []
        let range = 0
        for (let i = 0; i < lengths.length; i++) {
          parts.push(
            Buffer.from(data.slice(83, 99))
              .toString('hex')
              .slice(range, range + lengths[i])
              .toUpperCase()
          )
          range += lengths[i]
        }
        const shareLevel = () => {
          switch (parseInt(urlParams.get('l'))) {
            case 0:
              return 0
            case 1:
              return 1
            case 2:
              return 2
            default:
              return 'Unknown'
          }
        }
        const keyLevel = shareLevel(urlParams)
        const qrKeyInfo = {
          secretKey: Buffer.from(data.slice(1, 17)).toString('hex'),
          keyIndex: Buffer.from(data.slice(81, 83)).toString('hex'),
          sesame2PublicKey: Buffer.from(data.slice(17, 81)).toString('hex'),
          keyLevel,
          deviceModel: 'sesame_2',
          deviceName: urlParams.get('n'),
          deviceUUID: parts.join('-'),
        }
        setKeyList(
          [...keyList, qrKeyInfo].filter((row) => row.deviceModel !== 'wm_2')
        )
      })
      .catch((error) => {
        console.log(error)
      })
  }
  useEffect(() => {
    setHref(location.href)
    if (location.search) {
      API.post('Attendance', `/oauth`, {
        body: location.search.split(/[=,&]/)[1],
      })
        .then((res) => {
          setKeyList(
            keyList
              .concat(res.Items)
              .filter((row) => row.deviceModel !== 'wm_2')
          )
          setOpen(true)
        })
        .catch((err) => console.log(err))
    }
  }, [location.search])

  useEffect(() => {
    if (gStripe.customer.id) {
      setLimitWarning(false)
      setGetPersonalDevice(false)
      setKeyList([])
      navigate(`/devices/`)
    }
  }, [gStripe.customer.id])

  return (
    <Card sx={{ mb: '15px' }}>
      <CardHeader
        title={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant='h2' sx={{ ml: '9px' }}>
              新規デバイスを追加
            </Typography>
            <IconButton
              onClick={() => {
                setOpen(!open)
              }}
              sx={{ '&:hover': { color: 'primary.main' } }}
            >
              <Hider show={open}>
                <KeyboardArrowUpIcon />
                <KeyboardArrowDownIcon />
              </Hider>
            </IconButton>
          </Box>
        }
      />
      <CardContent>
        <Box sx={{ display: 'flex' }}>
          <Typography>新規デバイスの追加方法を選択</Typography>
          <CustomWidthTooltip
            title={
              <div>
                【QRコード】QRコードからデバイスをインポート
                <br />
                【OAuth認証】アクセス権限の付与で異なるユーザーのデバイスをインポート
                <br />
                【個人で登録済のデバイス】個人でセサミアプリに登録済みのデバイスをインポート
              </div>
            }
            placement='right-start'
          >
            <InfoIcon sx={{ color: '#cccccc', cursor: 'pointer', ml: '5px' }} />
          </CustomWidthTooltip>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Button varient='text' component='label' startIcon={<QrCodeIcon />}>
            QRコード
            <input
              type='file'
              hidden
              onChange={(event) => {
                if (event.target.files[0]) {
                  setKeyWarning(false)
                  readQrcode(event.target.files[0])
                }
              }}
            />
          </Button>
          <a
            href={`https://smarthome.candyhouse.co/?a=b&redirect_uri=${href}&client_id=sesamebiz&state=tse`}
          >
            <Button variant='text' startIcon={<KeyIcon />}>
              OAuth認証
            </Button>
          </a>
          <Button
            variant='text'
            disabled={getPersonalDevice === true}
            startIcon={<SmartphoneIcon />}
            onClick={(e) => {
              API.get('Attendance', `/user_device`, {
                queryStringParameters: {
                  sub: gAuth.loginSub,
                },
              })
                .then((res) => {
                  setKeyList(
                    keyList
                      .concat(res)
                      .filter((row) => row.deviceModel !== 'wm_2')
                  )
                  setGetPersonalDevice(true)
                })
                .catch((err) => console.log(err))
            }}
          >
            個人で登録済のデバイス
          </Button>
        </Box>
        <Hider show={keyList.length > 0}>
          <Grid item xs={12}>
            <Typography>入退室システムへ登録するデバイスを選択</Typography>
            <Box sx={{ mb: '5px' }}>
              {keyList.map((item, index) => {
                return (
                  <Button
                    key={index}
                    sx={{
                      marginRight: 1,
                      marginTop: 1,
                    }}
                    disabled={limitWarning === true}
                    variant='outlined'
                    onClick={() => {
                      setKeyWarning(false)
                      let tmp = [...keyList]
                      tmp.splice(index, 1)
                      setKeyList(tmp)
                      if (item.keyLevel === 0) {
                        setIsPending(true)
                        API.post('Attendance', `/device `, {
                          body: {
                            ...item,
                            companyID: gStripe.customer.id,
                          },
                        })
                          .then((res) => {
                            setLimitWarning(res)
                            API.get('Attendance', `/device`, {
                              queryStringParameters: {
                                companyID: gLoginEmployee.loginInfo.companyID,
                                access: JSON.stringify(
                                  gLoginEmployee.loginInfo.access
                                ),
                              },
                            })
                              .then((res) => {
                                gCompanyDevice.setDevices(res)
                                setIsPending(false)
                              })
                              .catch((err) => console.log(err))
                          })
                          .catch((err) => console.log(err))
                      } else {
                        setKeyWarning(true)
                      }
                    }}
                  >
                    {item.deviceName}
                  </Button>
                )
              })}
            </Box>
          </Grid>
        </Hider>
        <Hider show={keyWarning}>
          <Typography variant='h6' color='error'>
            登録失敗、デバイスの"オーナー"権限が必要です
          </Typography>
        </Hider>
        <Hider show={limitWarning}>
          <Typography
            variant='h6'
            sx={{ color: 'error.main', textDecoration: 'underline' }}
            component={Link}
            to={'/settings'}
          >
            デバイス登録数が上限に達しました。アップグレードを行うと更にデバイスの追加ができます。
          </Typography>
        </Hider>
      </CardContent>
      <Hider show={isPending}>
        <CircularProgress
          sx={{
            position: 'fixed',
            margin: 'auto auto',
            zIndex: '101',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}
          color='primary'
        />
      </Hider>
    </Card>
  )
}

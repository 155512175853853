import React, { useState, useContext } from 'react'
import { API } from 'aws-amplify'
import { navigate } from 'gatsby'
import MUIDataTable from 'mui-datatables'
import { GlobalStateContext } from '../../context/GlobalContextProvider'
import Layout from '../../layouts'
import Hider from '../../components/utils/Hider'
import AddDevice from '../../components/attendance/addDevice'
import AccessCheck from '../../components/attendance/accessCheck'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import {
  Typography,
  Grid,
  Collapse,
  IconButton,
  Tooltip,
  Button,
  Box,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import KeyIcon from '@mui/icons-material/Key'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import {VIotSwitch} from "../../components/iotswitch";

export default function Devices({ location }) {
  const { gCompanyDevice, gStripe,gIot, gLoginEmployee } =
    useContext(GlobalStateContext)
  const [open, setOpen] = useState(false)
  const [del, setDel] = useState(false)
  const [edit, setEdit] = useState(false)
  const [limitWarning, setLimitWarning] = useState(false)
  const getMuiTheme = () =>
    createTheme({
      palette: {
        primary: {
          main: '#28aeb1',
        },
        info: {
          light: '#cccccc',
          main: '#666666',
        },
        error: {
          light: '#db807c',
          main: '#CC4A44',
        },
      },
      typography: {
        fontFamily: "'Noto Sans JP', sans-serif",
        h2: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
        h4: {
          fontSize: '16px',
        },
        h5: {
          fontSize: '14px',
        },
        button: {
          textTransform: 'none',
        },
      },
      components: {
        MuiTypography: {
          styleOverrides: {
            root: {
              letterSpacing: '0.02rem',
            },
          },
        },
        MuiTableCell: {
          styleOverrides: { root: { '&:hover': { cursor: 'pointer' } } },
        },
      },
    })

  const checkShare = (selectedRows) => {
    let list = gCompanyDevice.devices.Items
    list.map((item) => {
      delete item.subUUID
    })
    const indexArr = selectedRows.data.map((i) => i.dataIndex)
    const checkShare = Array.from(indexArr, (k) => list[k])
    gCompanyDevice.setSelectedDevice(checkShare)
  }

  const deleteDevices = async (rowsSelected) => {
    gCompanyDevice.setIsPending(true)
    setLimitWarning(false)
    for (let i = 0; i < rowsSelected.length; i++) {
      API.del('Attendance', `/device`, {
        body: {
          deviceUUID: gCompanyDevice.devices.Items[rowsSelected[i]].deviceUUID,
          companyID: gStripe.customer.id,
        },
      })
        .then(() => {
          gCompanyDevice.getDevicesList(
            gStripe.customer.id,
            gLoginEmployee.loginInfo.access
          )
        })
        .catch((err) => console.log(err))
    }
  }

  return (
    <Layout location={location}>
      <AccessCheck access='デバイス'>
        <Grid container>
          <Grid item xs={12}>
            <Collapse in={open}>
              <AddDevice
                setOpen={setOpen}
                open={open}
                location={location}
                setLimitWarning={setLimitWarning}
                limitWarning={limitWarning}
              />
            </Collapse>
            <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                title={
                  <Box sx={{ display: 'flex' }}>
                    <Typography variant='h2' sx={{ fontWeight: 'bold' }}>
                      デバイス一覧
                    </Typography>
                    <Button
                      disableElevation
                      variant='contained'
                      size='small'
                      sx={{ color: 'white', marginLeft: '15px' }}
                      disabled={del}
                      startIcon={<KeyIcon />}
                      onClick={() => {
                        setEdit(!edit)
                      }}
                    >
                      {edit ? '選択' : '合鍵発行'}
                    </Button>
                  </Box>
                }
                data={gCompanyDevice.devices.Items}
                columns={[
                  {
                    name: 'deviceName',
                    label: 'デバイス名',
                    options: {
                      filter: true,
                      filterType: 'dropdown',
                      sort: true,
                    },

                  },
                  {
                    name: 'deviceUUID',
                    label: 'ロック状態',

                    options: {
                      filter: false,
                      sort: false,
                      customBodyRender: (deviceUUID, tableMeta, updateValue) => {


                        const secretKey = gCompanyDevice.devices.Items.find(item => item.deviceUUID === deviceUUID).secretKey;

                        return     <VIotSwitch  deviceUUID={deviceUUID} gIot={gIot} shareKey={secretKey} />;

                      }
                    }
                  }
                ]}
                options={{
                  elevation: 0,
                  filterType: 'checkbox',
                  selectableRows: edit || del ? 'multiple' : 'none',
                  setTableProps: () => {
                    return { fullWidth: true, size: 'small' }
                  },
                  onRowClick: (rowData, rowMeta) => {
                    if (typeof window !== 'undefined') {
                      localStorage.setItem(
                        'currDeviceUUID',
                        gCompanyDevice.devices.Items[rowMeta.rowIndex]
                          .deviceUUID
                      )
                    }
                    navigate('deviceDetails')
                  },
                  customToolbarSelect: (
                    selectedRows,
                    displayData,
                    setSelectedRows
                  ) => (
                    <div style={{ marginRight: '24px' }}>
                      <Hider show={edit}>
                        <Tooltip title={'合鍵を発行するユーザーを選択'}>
                          <Button
                            disableElevation
                            sx={{ color: 'white' }}
                            variant='contained'
                            size='small'
                            onClick={() => {
                              checkShare(selectedRows)
                              navigate('/devices/share')
                            }}
                          >
                            合鍵発行
                          </Button>
                        </Tooltip>
                        <Button
                          sx={{ color: 'error.main' }}
                          size='small'
                          onClick={() => {
                            deleteDevices(
                              selectedRows.data.map((i) => i.dataIndex)
                            )
                            setDel(false)
                            setSelectedRows([])
                          }}
                        >
                          デバイスを削除
                        </Button>
                      </Hider>
                    </div>
                  ),
                  customToolbar: () => {
                    return (
                      <>
                        <Tooltip
                          title={
                            open === true
                              ? '新規デバイス追加をキャンセル'
                              : '新規デバイスを追加'
                          }
                        >
                          <IconButton
                            onClick={() => {
                              setOpen(!open)
                            }}
                            sx={{ '&:hover': { color: 'primary.main' } }}
                          >
                            <Hider show={open}>
                              <CloseIcon />
                              <AddIcon />
                            </Hider>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='デバイスを削除'>
                          <IconButton
                            disabled={edit}
                            sx={{
                              color: del ? 'error.main' : '',
                              '&:hover': { color: 'error.main' },
                            }}
                            onClick={() => {
                              setDel(!del)
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )
                  },
                  textLabels: {
                    body: {
                      noMatch: '検索結果は０件です',
                      toolTip: 'ソート',
                      columnHeaderTooltip: (column) => `ソート ${column.label}`,
                    },
                    pagination: {
                      next: 'Next Page',
                      previous: 'Previous Page',
                      rowsPerPage: '表示数',
                      displayRows: '/',
                    },
                    toolbar: {
                      search: '検索',
                      downloadCsv: 'CSV',
                      print: '印刷する',
                      viewColumns: '列を表示',
                      filterTable: 'フィルター',
                    },
                    viewColumns: {
                      title: '列を表示',
                      titleAria: 'Show/Hide Table Columns',
                    },
                    filter: {
                      all: '全て',
                      title: 'フィルター',
                      reset: 'リセット',
                    },
                    selectedRows: {
                      text: '選択済み',
                    },
                  },
                }}
              />
            </ThemeProvider>
          </Grid>
        </Grid>
      </AccessCheck>
    </Layout>
  )
}
